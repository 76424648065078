import React from "react";
import * as TYPE from "../lib/types";
interface IProps {
  contents: TYPE.Cut;
}
export default function StoryBoard(props: IProps) {
  return (
    <React.Fragment>
      <div className="form-group my-section">
        <h2>{props.contents.title}</h2>
        <img src={props.contents.imageURL} />
        <p>{props.contents.notes}</p>
      </div>
    </React.Fragment>
  );
}
