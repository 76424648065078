import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey ? process.env.REACT_APP_apiKey : "",
  authDomain: process.env.REACT_APP_authDomain
    ? process.env.REACT_APP_authDomain
    : "",
  databaseURL: process.env.REACT_APP_databaseURL
    ? process.env.REACT_APP_databaseURL
    : "",
  projectId: process.env.REACT_APP_projectId
    ? process.env.REACT_APP_projectId
    : "",
  storageBucket: process.env.REACT_APP_storageBucket
    ? process.env.REACT_APP_storageBucket
    : "",
  messagingSenderId: process.env.REACT_APP_messagingSenderId
    ? process.env.REACT_APP_messagingSenderId
    : "",
  appId: process.env.REACT_APP_appId ? process.env.REACT_APP_appId : "",
  measurementId: process.env.REACT_APP_measurementId
    ? process.env.REACT_APP_measurementId
    : "",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//firebase.analytics();
export default firebase;
