import firebase from "./firebase";
export interface StorageList {
  folders: Array<firebase.storage.Reference>;
  files: Array<firebase.storage.Reference>;
}
export function listAllFiles(path: string): Promise<StorageList | undefined> {
  return new Promise((resolve) => {
    const storageRef: firebase.storage.Reference = firebase
      .storage()
      .ref()
      .child(path);
    storageRef
      .listAll()
      .then((res: firebase.storage.ListResult) => {
        const folderRefs: Array<firebase.storage.Reference> = res.prefixes;
        const itemRefs: Array<firebase.storage.Reference> = res.items;
        const storageList: StorageList = {
          folders: folderRefs,
          files: itemRefs,
        };
        resolve(storageList);
      })
      .catch((error: any) => {
        resolve(undefined);
      });
  });
}
