import React, { ReactText } from "react";
import { useState } from "react";
import firebase from "../lib/firebase";
import { listAllFiles, StorageList } from "../lib/utility";
import Section from "../componets/section";
import StoryBoard from "../componets/story-board";
import "./styles/home.css";
interface ImageFile {
  ref: firebase.storage.Reference;
  url: string;
}
const DEFAULT_FOLDER = "";
export default function Home() {
  const [imageURL0, setImageURL0] = useState(undefined as string | undefined);
  const [imageURL1, setImageURL1] = useState(undefined as string | undefined);
  const [imageURL2, setImageURL2] = useState(undefined as string | undefined);
  const [imageURL3, setImageURL3] = useState(undefined as string | undefined);
  const [title0, setTitle0] = useState(undefined as string | undefined);
  const [title1, setTitle1] = useState(undefined as string | undefined);
  const [title2, setTitle2] = useState(undefined as string | undefined);
  const [title3, setTitle3] = useState(undefined as string | undefined);
  const [text0, setText0] = useState("");
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [text3, setText3] = useState("");
  const [fullScreen, setFullScreen] = useState(false);
  const [theme, setTheme] = useState(undefined as string | undefined);
  const [imagefiles, setImageFiles] = useState([] as Array<ImageFile>);
  const [sampleMode, setSampleMode] = useState(false);
  const [currentSection, setCurrentSection] = useState(0);
  React.useEffect(() => {
    getSampleImages();
  }, []);
  return (
    <div className="App">
      <h1>ストーリーボード</h1>
      {!fullScreen ? (
        <React.Fragment>
          <div className="form-group theme">
            <label>テーマ</label>
            <input
              className="form-control"
              type="text"
              onChange={(e) => themeChangeEvent(e)}
            />
          </div>
          {sampleMode ? (
            <React.Fragment>
              <button
                className="btn btn-secondary"
                onClick={() => cancelSampleMode()}
              >
                入力画面に戻る
              </button>
              <div className="sample-image">
                {imagefiles.map((item) => (
                  <a>
                    <img
                      id={item.ref.name}
                      className="image-list"
                      src={item.url}
                      onClick={(
                        e: React.MouseEvent<HTMLImageElement, MouseEvent>
                      ) => imageSelectionEvent(e)}
                    />
                  </a>
                ))}
              </div>
            </React.Fragment>
          ) : (
            <div className="story-board">
              <Section
                contents={{ title: title0, imageURL: imageURL0, notes: text0 }}
                onChange={(e) => fileSelectionEvent0(e)}
                titleChange={(e) => titleChangeEvent0(e)}
                textChange={(e) => textChangeEvent0(e)}
                onClick={(e) => urlSelectionEvent0(e)}
                reselection={() => reselectionEvent0()}
              />
              <Section
                contents={{ title: title1, imageURL: imageURL1, notes: text1 }}
                onChange={(e) => fileSelectionEvent1(e)}
                titleChange={(e) => titleChangeEvent1(e)}
                textChange={(e) => textChangeEvent1(e)}
                onClick={(e) => urlSelectionEvent1(e)}
                reselection={() => reselectionEvent1()}
              />
              <Section
                contents={{ title: title2, imageURL: imageURL2, notes: text2 }}
                onChange={(e) => fileSelectionEvent2(e)}
                titleChange={(e) => titleChangeEvent2(e)}
                textChange={(e) => textChangeEvent2(e)}
                onClick={(e) => urlSelectionEvent2(e)}
                reselection={() => reselectionEvent2()}
              />
            </div>
          )}
          <div className="story-board-btn">
            <button
              className="btn btn-primary"
              onClick={() => fullScreenEvent()}
            >
              ストーリボードを表示する
            </button>
            <button className="btn btn-secondary" onClick={() => clearEvent()}>
              クリア
            </button>
            <button className="btn btn-success" onClick={() => toClipBoard()}>
              文章をクリップボードにコピー
            </button>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div>
            <button className="btn btn-primary" onClick={() => backEvent()}>
              入力画面に戻る
            </button>
          </div>

          <div className="theme-stroy-board">
            <h2>{theme ? theme : "テーマが入力されていません"}</h2>
          </div>
          <div className="story-board my-story-board">
            <StoryBoard
              contents={{ title: title0, imageURL: imageURL0, notes: text0 }}
            />
            <StoryBoard
              contents={{ title: title1, imageURL: imageURL1, notes: text1 }}
            />
            <StoryBoard
              contents={{ title: title2, imageURL: imageURL2, notes: text2 }}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
  function backEvent() {
    setFullScreen(false);
  }
  function cancelSampleMode() {
    setSampleMode(false);
  }
  function clearEvent() {
    setImageURL0(undefined);
    setImageURL1(undefined);
    setImageURL2(undefined);
    setImageURL3(undefined);
    setTitle0(undefined);
    setTitle1(undefined);
    setTitle2(undefined);
    setTitle3(undefined);
    setText0("");
    setText1("");
    setText2("");
    setText3("");
    setTheme(undefined);
  }
  function fileSelectionEvent0(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files[0]) {
      const file: File = e.target.files[0];
      const url = (window.URL || window.webkitURL).createObjectURL(file);
      setImageURL0(url);
    }
  }
  function toClipBoard() {
    const textArea: HTMLTextAreaElement = document.createElement("textarea");
    textArea.value =
      "テーマ：　" +
      theme +
      "\n=============================\n\n" +
      "タイトル：　" +
      title0 +
      "\n-----------------------------\n" +
      text0 +
      "\n-8<-8<-8<-8<-8<-8<-8<-8<-8<--\n\n\n" +
      "タイトル：　" +
      title1 +
      "\n-----------------------------\n" +
      text1 +
      "\n-8<-8<-8<-8<-8<-8<-8<-8<-8<--\n\n\n" +
      "タイトル：　" +
      title2 +
      "\n-----------------------------\n" +
      text2 +
      "\n-8<-8<-8<-8<-8<-8<-8<-8<-8<--\n\n\n";
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      const successful = document.execCommand("copy");
      const converted = successful ? true : false;
    } catch (err) {}
    document.body.removeChild(textArea);
  }
  function fileSelectionEvent1(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files[0]) {
      const file: File = e.target.files[0];
      const url = (window.URL || window.webkitURL).createObjectURL(file);

      setImageURL1(url);
    }
  }
  function fileSelectionEvent2(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files[0]) {
      const file: File = e.target.files[0];
      const url = (window.URL || window.webkitURL).createObjectURL(file);

      setImageURL2(url);
    }
  }
  function fileSelectionEvent3(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files[0]) {
      const file: File = e.target.files[0];
      const url = (window.URL || window.webkitURL).createObjectURL(file);

      setImageURL3(url);
    }
  }
  function fullScreenEvent() {
    setFullScreen(true);
  }

  function titleChangeEvent0(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setTitle0(value);
  }
  function titleChangeEvent1(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setTitle1(value);
  }
  function titleChangeEvent2(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setTitle2(value);
  }
  function titleChangeEvent3(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setTitle3(value);
  }
  function textChangeEvent0(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const value = e.target.value;
    setText0(value);
  }
  function textChangeEvent1(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const value = e.target.value;
    setText1(value);
  }
  function textChangeEvent2(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const value = e.target.value;
    setText2(value);
  }
  function textChangeEvent3(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const value = e.target.value;
    setText3(value);
  }
  function themeChangeEvent(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setTheme(value);
  }
  function urlSelectionEvent0(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    setCurrentSection(0);
    setSampleMode(true);
  }
  function urlSelectionEvent1(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    setCurrentSection(1);
    setSampleMode(true);
  }
  function urlSelectionEvent2(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    setCurrentSection(2);
    setSampleMode(true);
  }
  function reselectionEvent0() {
    setImageURL0(undefined);
  }
  function reselectionEvent1() {
    setImageURL1(undefined);
  }
  function reselectionEvent2() {
    setImageURL2(undefined);
  }
  function imageSelectionEvent(
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) {
    const id = e.currentTarget.id;
    const found = imagefiles.find((item) => {
      return item.ref.name === id;
    });
    if (found) {
      switch (currentSection) {
        case 0:
          setImageURL0(found.url);
          break;
        case 1:
          setImageURL1(found.url);
          break;
        case 2:
          setImageURL2(found.url);
          break;
      }
      setSampleMode(false);
    }
  }
  function getSampleImages(): void {
    listAllFiles(DEFAULT_FOLDER).then((result: StorageList | undefined) => {
      const updateFiles: Array<ImageFile> = [];
      if (result) {
        for (let i = 0; i < result.files.length; i++) {
          const file = result.files[i];
          file.getDownloadURL().then((url) => {
            const image: ImageFile = { ref: file, url: url };
            updateFiles.push(image);
          });
          setImageFiles(updateFiles);
        }
      }
    });
  }
}
