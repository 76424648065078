import React from "react";

import * as TYPE from "../lib/types";
import "./styles/section.css";
interface IProps {
  contents: TYPE.Cut;
  textChange:
    | ((event: React.ChangeEvent<HTMLTextAreaElement>) => void)
    | undefined;
  titleChange:
    | ((event: React.ChangeEvent<HTMLInputElement>) => void)
    | undefined;
  reselection:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  onClick:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
}

export default function Section(props: IProps) {
  const imageFileField: React.RefObject<HTMLInputElement> =
    React.createRef<HTMLInputElement>();

  return (
    <React.Fragment>
      <div className="form-group my-section">
        <input
          className="hidden"
          type="file"
          onChange={props.onChange}
          ref={imageFileField}
        />
        {props.contents && props.contents.imageURL ? (
          <React.Fragment>
            <div className="select-file">
              <img src={props.contents.imageURL} />
              <button className="btn btn-success" onClick={props.reselection}>
                イメージの再選択
              </button>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <label>画像ファイル</label>
            <div className="select-file">
              <button
                className="btn btn-success"
                onClick={() => imageSelectEvent()}
              >
                選択
              </button>
              <button className="btn btn-success" onClick={props.onClick}>
                サンプルから選択
              </button>
            </div>
          </React.Fragment>
        )}

        <label>タイトル</label>
        <input
          className="form-control"
          onChange={props.titleChange}
          value={props.contents.title ? props.contents.title : ""}
        />
        <label>説明</label>
        <textarea
          className="form-control"
          onChange={props.textChange}
          value={props.contents.notes}
        ></textarea>
      </div>
    </React.Fragment>
  );
  function imageSelectEvent() {
    if (imageFileField.current) {
      imageFileField.current.click();
    }
  }
}
